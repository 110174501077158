import { ClassAttributes, InputHTMLAttributes, TextareaHTMLAttributes, SelectHTMLAttributes, useState } from "react"
import * as Yup from "yup";
import { Formik, Form, useField, FieldHookConfig } from 'formik';

import { DatePickerField } from "./DatePicker";

interface ITextInputProps {
  label: string;
  description?: string;
}

interface ISelectInputProps {
  label: string;
  description?: string;
  bold: boolean;
}

const TextInput = ({ label, description = '', ...props }: ITextInputProps & InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement> & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  return (
    <div className={`mb-4 lg:mb-2 w-full px-4`}>
      <label className={`block mb-1`} htmlFor={props.id || props.name}>{label}</label>
      <input className={`text-lg rounded-md border-2 bg-gray-100 px-2 py-2 w-full ${meta.error ? 'border-red-500' : 'border-gray-300'}`} {...field} {...props} />
      {description && <div className={`mt-1 text-xs`}>{description}</div>}
    </div>
  );
};

const Textarea = ({ label, description = '', ...props }: ITextInputProps & TextareaHTMLAttributes<HTMLTextAreaElement> & ClassAttributes<HTMLTextAreaElement> & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  return (
    <div className={`mb-4 lg:mb-2 w-full px-4`}>
      <label className={`block mb-1`} htmlFor={props.id || props.name}>{label}</label>
      <textarea className={`text-lg rounded-md border-2 bg-gray-100 px-2 py-2 w-full ${meta.error ? 'border-red-500' : 'border-gray-300'}`} {...field} {...props} />
      {description && <div className={`text-xs`}>{description}</div>}
    </div>
  );
};
const DateInput = ({ label, description = '', ...props }: ITextInputProps & InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement> & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  return (
    <div className={`mb-4 lg:mb-2 w-full px-4`}>
      <label className={`block mb-1`} htmlFor={props.id || props.name}>{label}</label>
      <DatePickerField className={`text-lg rounded-md border-2 bg-gray-100 px-2 py-2 w-full ${meta.error ? 'border-red-500' : 'border-gray-300'}`} {...field} {...props} />
      {description && <div className={`mt-1 text-xs`}>{description}</div>}
    </div>
  );
};

const Select = ({ label, description = '', bold, ...props }: ISelectInputProps & SelectHTMLAttributes<HTMLSelectElement> & ClassAttributes<HTMLSelectElement> & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  return (
    <div className={`mb-4 lg:mb-2 w-full px-4`}>
      <label className={`block ${bold ? 'mb-2 text-lg font-bold' : 'mb-1'}`} htmlFor={props.id || props.name}>{label}</label>
      <select className={`text-lg rounded-md border-2 bg-gray-100 px-2 py-2 w-full ${meta.error ? 'border-red-500' : 'border-gray-300'}`} {...field} {...props} />
    </div>
  );
};

// And now we can use these
const OffseasonReg = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [program, setProgram] = useState('smallgroup');
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const pricing = params.get('pricing') ?? 'advanced';

  const onProgramChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setProgram(event.target.value);
  }

  if (isSubmitted) {
    return(
      <div className={`mt-8 flex justify-center`}>
        <div className={`w-full bg-white py-6 px-4 rounded shadow-lg`}>
          <h3>Registration Sent</h3>
          <p className={`mt-4 mb-0`}>Your registration has been sent for review. Once we've had a chance to review your details we'll send you a payment link.</p>
        </div>
      </div>
    )
  } else {
    return (
      <>
        <Formik
          initialValues={{
            name: '',
            email: '',
            birth_year: '',
            last_team: '',
            years_of_training: '',
            start_date: '',
            end_date: '',
            days_week_spring: '',
            days_week_summer: '',
            dates_absense: '',
            past_injuries: '',
            on_ice_goals: '',
            off_ice_goals: '',
            days_times: '',
            other_group_members: '',
            program_pricing: pricing,
            duration: 'full',
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .required("Required"),
            email: Yup.string()
              .email('Invalid email address')
              .required("Required"),
            birth_year: Yup.string()
              .required("Required"),
            last_team: Yup.string()
              .required("Required"),
            years_of_training: Yup.string()
              .required("Required"),
            start_date: Yup.string()
              .required("Required"),
            on_ice_goals: Yup.string()
              .required("Required"),
            off_ice_goals: Yup.string()
              .required("Required"),
            program_pricing: Yup.string()
              .required("Required"),
            duration: Yup.string()
              .required("Required")
          })}
          onSubmit={async (values, { setSubmitting }) => {
            return fetch(process.env.REACT_APP_EMAIL_API + '', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: values.email,
                name: values.name,
                birth_year: values.birth_year,
                last_team: values.last_team,
                years_of_training: values.years_of_training,
                start_date: values.start_date,
                end_date: values.end_date,
                days_week_spring: values.days_week_spring,
                days_week_summer: values.days_week_summer,
                dates_absense: values.dates_absense,
                past_injuries: values.past_injuries,
                on_ice_goals: values.on_ice_goals,
                off_ice_goals: values.off_ice_goals,
                days_times: values.days_times,
                other_group_members: values.other_group_members,
                program: program,
                program_pricing: values.program_pricing,
                duration: values.duration,
                token: process.env.REACT_APP_FORM_TOKEN
              })
            }).then((res) => {
              setSubmitting(false);
              setIsSubmitted(true);
            });
          }}
        >
          {({
            isSubmitting,
            values,
            errors
          }) => (
            <Form className={`bg-white py-6 px-4 rounded shadow-lg`}>
              <div className={`md:flex mb-4`}>
                <TextInput
                  label="Athlete's Name"
                  name="name"
                  type="text"
                />
                <TextInput
                  label="Parent Email"
                  name="email"
                  type="email"
                  />
              </div>

              <div className={`md:flex mb-4`}>
                <TextInput
                  label="Birth Year"
                  name="birth_year"
                  type="number"
                  min="1950"
                  max="2023"
                />
                <TextInput
                  label="Last Season's Team"
                  name="last_team"
                  type="text"
                  />
              </div>
              <div className={`md:flex mb-4`}>
                <TextInput
                  label="Years of Training"
                  name="years_of_training"
                  description="Training in a gym/fitness classes."
                  type="number"
                  min="1"
                  max="100"
                />
                <DateInput
                  label="Desired Start Date"
                  name="start_date"
                  type="text"
                  />
              </div>
              <div className={`md:flex-col mb-4`}>
                <Textarea
                  label="Significant Dates of Absense"
                  description="Over the spring/summer training period."
                  name="dates_absense"
                  />
              </div>

              <div className={`md:flex mb-4`}>
                <Textarea
                  label="Significant Past Injuries"
                  name="past_injuries"
                  />
              </div>

              <div className={`md:flex mb-4`}>
                <Textarea
                  label="On-ice Goals"
                  name="on_ice_goals"
                  />
              </div>

              <div className={`md:flex mb-4`}>
                <Textarea
                  label="Off-ice Goals"
                  name="off_ice_goals"
                  />
              </div>

              <div className={`md:flex`}>
                <div className={`md:w-1/2 md:mr-4`}>
                  <div className={`mb-2 w-full px-4`}>
                    <p className={`font-bold text-lg mb-2`}>Offseason Program</p>
                    <select onChange={onProgramChange} className={`text-lg rounded-md border-2 bg-gray-100 px-2 py-2 mb-4 w-full`}>
                      <option value="smallgroup">Small Group</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div>

                  {program === 'custom' ?
                  <div className={`w-full`}>
                    <div className={`mb-4`}>
                      <DateInput
                        label="Estimated Last Training Date"
                        name="end_date"
                        description="For the summer/spring offseason period."
                        type="text"
                        />
                    </div>
                    <div className={`mb-4`}>
                      <TextInput
                        label="Training Days Per Week (Spring)"
                        name="days_week_spring"
                        type="number"
                        min="1"
                        max="7"
                      />
                    </div>
                    <div className={`mb-4`}>
                      <TextInput
                        label="Training Days Per Week (Summer)"
                        name="days_week_summer"
                        type="number"
                        min="1"
                        max="7"
                      />
                    </div>
                  </div>
                :
                  <div className={`w-full`}>
                    <div className={`mb-4`}>
                      <Textarea
                        label="Desired Days and Times for Training"
                        name="days_times"
                        />
                    </div>
                    <div className={`mb-4`}>
                      <Textarea
                        label="Name of Other Group Members (if any)"
                        name="other_group_members"
                        />
                    </div>
                  </div>
                }
                </div>
                <div className={`md:w-1/2 md:ml-4`}>
                  <div className={`mb-4`}>
                    <Select label="Program Pricing" bold={true} name="program_pricing">
                      <option value="advanced">Advanced (3x/week)</option>
                      <option value="elite">Elite (5x/week)</option>
                      <option value="mix">Mix (3x/week spring, 5x/week summer)</option>
                    </Select>
                  </div>
                  {values.program_pricing !== "mix" &&
                    <div className={`mb-4`}>
                      <Select label="Program Duration" bold={true} name="duration" disabled={values.program_pricing === "mix"}>
                        <option value="full">Full Season</option>
                        <option value="summer">Summer Only</option>
                      </Select>
                    </div>
                  }
                  <div className={`mb-4`}>
                    <div className={`ml-4`}>
                      <p className={`font-bold text-lg mt-10 mb-2`}>Registration Details</p>
                      <p className={`mb-2`}>Program: {
                        values.program_pricing === 'elite' ? 'Elite (5x/week)'
                        : values.program_pricing === "mix" ? 'Mix (3x/week spring, 5x/week summer)'
                        : 'Advanced (3x/week)'}</p>
                      {values.program_pricing !== "mix" &&
                        <p className={`mb-2`}>Duration: {values.duration === 'full' ? 'Full Season' : 'Summer Only'}</p>
                      }
                      <p className={`mb-2`}>Cost: <span className={`font-bold`}>
                        {values.program_pricing === "elite" ?
                          <>{values.duration === 'full' ? '$2400.00' : '$1500.00'}</>
                        : values.program_pricing === "mix" ?
                          <>{'$2150.00'}</>
                        :
                          <>{values.duration === 'full' ? '$1750.00' : '$1000.00'}</>
                        }
                      </span></p>
                      <p className={`italic`}>* Tax added at payment.</p>
                    </div>
                  </div>
                </div>
              </div>

              {Object.keys(errors).length > 0 &&
                <div className={`text-lg rounded-md border-2 bg-gray-100 mx-4 px-2 py-2 text-red-500 border-red-500`}>Please fill in all required form fields.</div> 
              }

              <div className={`mt-4 ml-4`}>
                <button type="submit" disabled={isSubmitting || Object.keys(errors).length > 0} className={`btn bg-green-500 enabled:hover:bg-green-600 enabled:hover:text-white text-white flex items-center`}>{isSubmitting ? 'Registration submitting...' : 'Submit Registration'}</button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
};

export default OffseasonReg;
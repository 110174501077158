import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Product from '../components/Product';
import Heading from '../components/Heading';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

import OffseasonLogo from "../assets/img/offseason24-logo.jpg";

function Products() {
    const [groupPrices, setGroupPrices] = useState([]);
    const [ptPrices, setPtPrices] = useState([]);

    useEffect(() => {
        async function fetchProducts(category) {
            const params = {
                action: 'products',
                category: category
            };
            const options = {
                method: 'GET',
            };
            const api = process.env.REACT_APP_SERVER_URL + '?' + (new URLSearchParams(params)).toString();
            const response = await fetch(api, options);
            return await response.json();
        }

        fetchProducts('pt').then(results => {
            setPtPrices(results);
        });
        fetchProducts('small-group').then(results => {
            setGroupPrices(results);
        });
    }, []);

    return (
        <>
            <Heading title={`Services`} />

            <div className={`bg-gray-100 py-6 lg:py-12`}>
                <div className={`container max-w-6xl mx-auto`}>
                    <h2 className="mb-6">Off-Season Training</h2>

                    <div className={`flex flex-col lg:flex-row`}>
                        <div className={`lg:w-1/2 lg:pr-2 order-2`}>
                            <p className={`mb-6`}>Off-Season at Busby SC is all new for 2024. After 9 years of running offseason programs I'm taking what I've learned and making my 10th year the best one yet!</p>

                            <p className={`mb-6`}>My programs have helped hundreds of athletes improve their game on the ice including over two dozen professional players, and I've worked along side some of the best strength coaches in the NHL to reinforce the quality of the training.</p>

                            <p className={`mb-6`}>Registration numbers will be very limited this spring/summer to keep the quality of the training at a maximum.</p>
                        </div>
                        <div className={`lg:w-1/2 lg:pl-2 order-1 lg:order-3 mb-6 lg:mb-0`}>
                            <img src={OffseasonLogo} className={`w-full rounded-md`} alt={`Offseason24 Logo`} />
                        </div>
                    </div>

                    <p className={`mb-6 flex`}>
                        <Link to={`/offseason`} className={`btn btn--dark flex items-center`} role="link">Offseason X Details <BsFillArrowRightCircleFill className={`text-xl ml-2`} /></Link>
                    </p>
                </div>
            </div>

            <div className={`bg-white py-12`}>
                <div className={`container max-w-6xl mx-auto`}>
                    <h2 className="mb-6">Personal Training</h2>

                    <p className={`mb-6`}>Personal training will always be the best and fastest way to achieve your goals. The sessions are fully customized 60-minute one-on-one training with Pat. 50 and 20-session packages include a program you can follow beyond the personal training sessions, at no additional cost. These packages also include assessing and monitoring body composition and performance tracking (testing).</p>

                    <div className="mt-12 grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {ptPrices.map((item, index) => (
                            <Product bg={`gray-100`} details={item} key={index} category={"pt"} showDescription={true} />
                        ))}
                    </div>
                </div>
            </div>

            <div className={`bg-gray-100 py-12`}>
                <div className={`container max-w-6xl mx-auto`}>
                    <h2 className="mb-6">Small Group Training</h2>

                    <p className={`mb-6`}>Busby Strength &amp; Conditioning was built to accommodate optimal small group training. Pat will coach 60-minute sessions using all the tools the gym has to offer. He prefers to keep the groups at a maximum of 5 athletes to allow for sufficient coaching and individual needs.</p>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {groupPrices.map((item, index) => (
                            <Product bg={`white`} details={item} key={index} plan={item.interval ? true : false} category={"small-group"} showDescription={true} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Products;

import Heading from '../components/Heading';

function About() {
    return (
        <>
            <Heading title={`About Busby Strength & Conditioning`} />
           
            <div className={`bg-gray-100 py-6 lg:py-12`}>
                <div className={`container max-w-6xl mx-auto`}>
                    <h2 className={`mb-6`}>About Pat</h2>
                    <p>Pat is a professional trainer certified through the National Strength and Conditioning Association Certified Strength &amp; Conditioning Specialist (NSCA-CSCS) with 15 years of experience training youth, amateur and professional athletes in a variety of sports. As a former founder and co-owner of ProEdge Sports Conditioning, Pat has worked with thousands of athletes throughout HRM and beyond. He led ProEdge to earn multiple awards throughout his tenure, as well as winning personal awards as a trainer.</p>

                    <p>Pat’s training approach is a blend of the latest scientific research in athletic conditioning and an old-school hands-on work ethic. Not satisfied with the more-is-better philosophy, he uses his experience and knowledge to ensure that his clients are being pushed when needed while taking steps to safely improve their performance through progressive overload.</p>

                    <p>Pat is a multisport athlete himself and understands the physical requirements, specific movement patterns,and  injury risks for many sports. A committed, life-long learner in the field of athletics, Pat stays abreast of the latest research and training and enjoys traveling annually to the Training for Hockey clinic in Colorado where he learns from all the best NHL and USA NTDP coaches.</p>

                    <p>His two favourite quotes are “Anyone can make someone sweat, but not everyone can make someone better” and “The enemy of great is good.”</p>
                </div>
            </div>
            <div className={`bg-white py-6 lg:py-12`}>
                <div className={`container max-w-6xl mx-auto`}>
                    <h2 className={`mb-6`}>The Studio</h2>

                    <p>Busby Strength &amp; Conditioning studio in Bedford represents more than a decade of hands-on experience in athletic studio design. Pat’s innovative training techniques and equipment delivers exactly what you need to get better, faster, and stronger. The 1,200 square foot facility has some of the most modern training devices specific for athletes, equipment you won’t find in other gyms, as well as all the required basics.</p>

                    <ul>
                        <li>45’x12’ turf</li>
                        <li>Strexbox: flywheel eccentric overload training tools</li>
                        <li>Blazepods: reactive agility lights</li>
                        <li>Matrix-S Drive Treadmill with parachute and sled push option</li>
                        <li>Push Training: velocity based training device and app</li>
                        <li>Speed bands and anchors to optimize acceleration and change of direction drills</li>
                        <li>Canadian made custom Gorila racks, barbell and plates</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default About;

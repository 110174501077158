import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

// Layout components
import Header from './layout/Header';
import Footer from './layout/Footer';

// Pages
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import Success from './pages/Success';

import Offseason from './pages/Offseason';
import OffseasonRegister from "./pages/OffseasonRegister";

function App() {
    const api_regex = /^\/api\/.*/
    // if using "/api/" in the pathname, don't use React Router
    if (api_regex.test(window.location.pathname)) {
        return <div /> // must return at least an empty div
    } else {
        return (
            <Router>
                <Header />
                <div className={`bg-white`}>
                    <Switch>
                        <Route path="/success">
                            <Success />
                        </Route>
                        <Route path="/offseason/register">
                            <OffseasonRegister />
                        </Route>
                        <Route path="/offseason">
                            <Offseason />
                        </Route>
                        <Route path="/">
                            <Home />
                            <Products />
                            <About />
                        </Route>
                    </Switch>
                </div>
                <Footer />
            </Router>
        );
    }
}

export default App;

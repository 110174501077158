// Interface definitions
interface IPriceFormat {
    amount: number;
    currency: string;
    quantity: number;
}

const formatPrice = ({ amount, currency, quantity }: IPriceFormat) => {
    const numberFormat = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency,
        currencyDisplay: 'symbol',
    });
    const parts = numberFormat.formatToParts(amount);
    let zeroDecimalCurrency = true;
    for (let part of parts) {
        if (part.type === 'decimal') {
            zeroDecimalCurrency = false;
        }
    }
    amount = zeroDecimalCurrency ? amount : amount / 100;
    const total = (quantity * amount);
    return numberFormat.format(total);
};

export default formatPrice;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, Redirect } from 'react-router-dom';
import formatPrice from '../utils/format-price';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function Success(props) {
    const [session, setSession] = useState({});
    // const [schedule, setSchedule] = useState({});
    const location = useLocation();
    const sessionId = location.search.replace('?session_id=', '');
    const [purchaseStatus, setPurchaseStatus] = useState('Processing purchase');
    const [category, setCategory] = useState("");

    useEffect(() => {
        function fetchSession() {
            axios.post(process.env.REACT_APP_SERVER_URL, {
                action: "session",
                sessionId: sessionId
            })
            .then((response) => {
                // General success
                if (response.data.customer) {
                    const sessionData = {
                        email: response.data.customer_details.email ?? null,
                        type: 'One-time payment',
                        subtotal: formatPrice({
                            amount: response.data.amount_subtotal,
                            currency: 'CAD',
                            quantity: 1
                        }),
                        tax: formatPrice({
                            amount: response.data.total_details.amount_tax,
                            currency: 'CAD',
                            quantity: 1
                        }),
                        total: formatPrice({
                            amount: response.data.amount_total,
                            currency: 'CAD',
                            quantity: 1
                        })
                    }

                    // Process subscription
                    if (response.data.subscription && response.data.metadata.iterations) {
                        createSchedule(response.data.subscription, response.data.metadata.iterations, sessionData);
                    } else {
                        setCategory(response.data.metadata?.category);
                        setSession(sessionData);
                        setPurchaseStatus('Purchase complete');
                    }
                }
            });
        }

        function createSchedule(sub_id, iterations, sessionData) {
            axios.post(process.env.REACT_APP_SERVER_URL, {
                action: "create-schedule",
                subscriptionId: sub_id
            })
            .then((response) => {
                if (response.data.id && iterations && response.data.current_phase.start_date && response.data.phases[0].items[0].price) {
                    updateSchedule(response.data.id, iterations, response.data.current_phase.start_date, response.data.phases[0].items[0].price, sessionData);
                } else {
                    setPurchaseStatus('Error processing purchase');
                }
            });
        }

        function updateSchedule(sched_id, iterations, start, price, sessionData) {
            axios.post(process.env.REACT_APP_SERVER_URL, {
                action: "update-schedule",
                scheduleId: sched_id,
                iterations: iterations,
                startDate: start,
                price: price,
            })
            .then((response) => {
                sessionData.type = 'Monthly payments';
                sessionData.duration = `${iterations} months`;
                setSession(sessionData);
                setPurchaseStatus('Purchase complete');
            });
        }

        if (sessionId) {
            fetchSession();
        }
    }, [sessionId]);

    if (!sessionId) {
        return <Redirect to="/" />
    } else {
        return (
            <>
                <div className={`bg-blue-900 mb-12 lg:mb-0`}>
                    <div className={`container max-w-6xl mx-auto`}>
                        <div className={`py-6 lg:py-12`}>
                            <h1 className={`text-white text-center mb-0`}>{purchaseStatus}</h1>
                        </div>
                    </div>
                </div>

                {session.email ?
                    <div className={`bg-white py-6 lg:py-12`}>
                        <div className={`container max-w-6xl mx-auto`}>
                            <div className={``}>
                                {category === "bootcamp" ?
                                    <div className={`max-w-sm mx-auto mb-12`}>
                                        <h2 className={`mb-2`}>Welcome to Bootcamps!</h2>

                                        <p className={`mb-2`}>Use the link below to register for classes. Save the link and sign up at the beginning of each month.</p>

                                        <p><a href="/bootcamp" className={`btn btn--dark`}>Register for Bootcamps</a></p>
                                    </div>
                                : null}

                                <p className="text-center">Thank you for your payment! Your details are below:</p>

                                <div className={`max-w-sm mx-auto shadow-lg rounded-lg`}>
                                    <div className={`py-2 px-4 bg-gray-100 flex justify-between rounded-tr-lg rounded-tl-lg`}>
                                        <div className={`font-bold`}>Email</div>
                                        <div>{session.email}</div>
                                    </div>
                                    <div className={`py-2 px-4 bg-white flex justify-between`}>
                                        <div className={`font-bold`}>Payment Type</div>
                                        <div>{session.type}</div>
                                    </div>
                                    <div className={`py-2 px-4 bg-gray-100 flex justify-between`}>
                                        <div className={`font-bold`}>Subtotal</div>
                                        <div>{session.subtotal}</div>
                                    </div>
                                    <div className={`py-2 px-4 bg-white flex justify-between`}>
                                        <div className={`font-bold`}>Tax</div>
                                        <div>{session.tax}</div>
                                    </div>
                                    <div className={`py-2 px-4 bg-gray-100 flex justify-between rounded-br-lg rounded-bl-lg`}>
                                        <div className={`font-bold`}>Total</div>
                                        <div>{session.total}</div>
                                    </div>
                                    {session.duration ?
                                        <div className={`py-2 px-4 bg-white flex justify-between`}>
                                            <div className={`font-bold`}>Duration</div>
                                            <div>{session.duration}</div>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={`bg-gray-100 py-6 lg:py-12`}>
                        <div className={`container max-w-6xl mx-auto`}>
                            <div className={`flex items-center justify-center`}>
                                <Loader type="Oval" color="#000000" height={36} width={36}/>
                                <p className="text-center m-0 ml-6">Please wait while we finalize your payment...</p>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default Success;

import Logo from "../assets/img/busby-sc-logo.png";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";

function Header() {
    return (
        <div className={`bg-white py-6`}>
            <div className={`container max-w-6xl mx-auto flex flex-col md:flex-row justify-center items-center`}>
                <div className={``}><Link to="/"><img src={Logo} className={`logo`} alt={`Busby Strength &amp; Conditioning`} /></Link></div>
                <div className={`mt-6 md:mt-0 md:ml-36 text-blue-900 text-center md:text-left`}>
                    <a target="_blank" rel="noreferrer"
                        href="https://goo.gl/maps/VbN2TzmV7kZ6dnba9"
                        className={`flex items-center text-blue-900 mb-2 font-bold no-underline`}>
                        <div className={`mr-2`}><FaMapMarkerAlt className={`text-4xl`} /></div>
                        <div>311 Rocky Lake Dr, Unit 5<br />Bedford, NS</div>
                    </a>
                    <p className={`mb-0`}><a href={`mailto:pat@busbysc.com`} className={`btn btn--dark`}>pat@busbysc.com</a></p></div>
            </div>
        </div>
    )
}

export default Header;

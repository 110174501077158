import Heading from '../components/Heading';
import OffseasonReg from '../components/OffseasonReg';

function OffseasonRegister() {
    return (
        <>
            <Heading title={`Off-Season Training`} />

            <div className={`bg-gray-100 py-6 lg:py-12`}>
                <div className={`container max-w-6xl mx-auto`}>
                    <h2 className={`mb-6`}>Register for Offseason</h2>

                    <p className={`mb-6`}>Complete the form from the type of offseason interested in and we'll review your information and confirm your enrollment. A follow up email will be sent from Pat with registration detials and a link for payment.</p>

                    <OffseasonReg />
                </div>
            </div>
        </>
    );
};

export default OffseasonRegister;

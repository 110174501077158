import { Link } from 'react-router-dom';
import { BsBoxArrowRight } from 'react-icons/bs';

import OffseasonLogo from "../assets/img/offseason24-logo.jpg";

import Heading from '../components/Heading';

function Offseason() {
    const button = {
        title: 'Register for Offseason X!',
        link: '/offseason/register'
    }

    return (
        <>
            <Heading title={`Offseason X at BusbySC`} button={button} />

            <div className={`bg-gray-100 py-6 lg:py-12`}>
                <div className={`container max-w-6xl mx-auto`}>
                    <div className={`flex justify-center mb-8`}>
                        <img src={OffseasonLogo} className={`w-full md:w-3/4 rounded-md`} alt={`Offseason24 Logo`} />
                    </div>

                    <h2 className={`mb-4`}>Pricing and Registration</h2>

                    <p className={`mb-4`}>Choose the Offseason package that you'd like to register for. You'll be presented with a registration form where you can choose between Custom or Small Group packages, as well as Full Season or Summer Only options.</p>

                    <div className="mt-12 grid grid-cols-1 lg:grid-cols-3 gap-8">
                        <div className="flex flex-col rounded-lg shadow-lg">
                            <div className={`relative flex-grow bg-gray-100 rounded-t-lg p-6`}>
                                <h3 className="mb-0">Advanced (3x/week)</h3>

                                <ul className={`mt-3 mb-0`}>
                                    <li>Full Season: $1750.00</li>
                                    <li>Summer Only: $1000.00</li>
                                </ul>
                            </div>
                        
                            <div className="bg-blue-900 rounded-b-lg p-6">
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between">
                                        <Link className="btn flex items-center" to={'/offseason/register?pricing=advanced'}>
                                            Register for Advanced
                                            <BsBoxArrowRight className={`text-xl ml-2`} />
                                        </Link>
                                    </div>
                                    <div>
                                        <p className="mt-3 mb-0"><span className="text-sm text-gray-200">Tax added at payment.</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col rounded-lg shadow-lg">
                            <div className={`relative flex-grow bg-gray-100 rounded-t-lg p-6`}>
                                <h3 className="mb-0">Elite (5x/week)</h3>

                                <ul className={`mt-3 mb-0`}>
                                    <li>Full Season: $2400.00</li>
                                    <li>Summer Only: $1500.00</li>
                                </ul>
                            </div>
                        
                            <div className="bg-blue-900 rounded-b-lg p-6">
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between">
                                        <Link className="btn flex items-center" to={'/offseason/register?pricing=elite'}>
                                            Register for Elite
                                            <BsBoxArrowRight className={`text-xl ml-2`} />
                                        </Link>
                                    </div>
                                    <div>
                                        <p className="mt-3 mb-0"><span className="text-sm text-gray-200">Tax added at payment.</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col rounded-lg shadow-lg">
                            <div className={`relative flex-grow bg-gray-100 rounded-t-lg p-6`}>
                                <h3 className="mb-0">Mix Program</h3>

                                <ul className={`mt-3 mb-0`}>
                                    <li>Spring: 3x/week</li>
                                    <li>Summer: 5x/week</li>
                                    <li>$2150.00</li>
                                </ul>
                            </div>
                        
                            <div className="bg-blue-900 rounded-b-lg p-6">
                                <div className="flex flex-col">
                                    <div className="flex items-center justify-between">
                                        <Link className="btn flex items-center" to={'/offseason/register?pricing=mix'}>
                                            Register for Mix
                                            <BsBoxArrowRight className={`text-xl ml-2`} />
                                        </Link>
                                    </div>
                                    <div>
                                        <p className="mt-3 mb-0"><span className="text-sm text-gray-200">Tax added at payment.</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`container max-w-6xl mx-auto mt-10`}>
                    <h2 className={`mb-6`}>Program Details</h2>

                    <h3 className={`mb-4`}>Dates</h3>

                    <ul className={`mt-0 mb-4`}>
                        <li><strong>Spring:</strong> April 22-June 28</li>
                        <li><strong>Summer:</strong> July 1-August 30</li>
                    </ul>

                    <p>Offseason has two program options, each with flexibility for different training schedules:</p>
                </div>

                <div className={`container max-w-6xl mx-auto lg:flex`}>
                    <div className={`lg:w-1/2 lg:pr-4 mb-4 lg:mb-0`}>
                        <h3 className={`mb-4`}>Custom Program</h3>

                        <p className={`mb-4`}>A custom written program will be shared through an email invitation to download the TrueCoach app to be able to view and edit the workouts.</p>

                        <p className={`mb-4`}>A coach will be available to the athlete to assist with questions, technical training, spotting, set up, etc.</p>

                        <p className={`mb-0`}><strong>Coaching hours:</strong></p>
                        <ul className={`mt-0 mb-4`}>
                            <li>April/May: M-F 4pm-8pm</li>
                            <li>June/July/August: 8am-12pm</li>
                        </ul>

                        <p className={`mb-4`}>An athlete may come outside of the coaching hours but is not guaranteed to have a coach present. A code will be provided to gain entry into the gym if outside of business hours.</p>

                        <p className={`mb-0`}>*If testing has been requested then coach Pat will follow up to schedule a set time to have testing/body composition completed.</p>
                    </div>
                    <div className={`lg:w-1/2 lg:pr-4`}>
                        <h3 className={`mb-4`}>Small Group</h3>

                        <p className={`mb-4`}>An athlete may sign up as an individual or a group. If there is a group that would like to work together then make sure you fill that section on the form.</p>

                        <p className={`mb-4`}>Upon completing the athlete form from the website, Pat will reach out to confirm availability of the requested times and payment structure.</p>

                        <p className={``}>Each session is 60 minutes and will be at Busby Strength and Conditioning unless otherwise stated. Fridays will often be off-site at either Metropolitan Field in Sackville to use the track, Citadel hill for speed work, or at the beach volleyball court in Dewolfe Park, Bedford.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Offseason;
import React, { useEffect, useState } from 'react';
import getStripe from '../utils/get-stripejs';
import formatPrice from '../utils/format-price';
import {BsStarFill, BsBoxArrowRight} from 'react-icons/bs';

const api = process.env.REACT_APP_SERVER_URL ?? '';

interface IProps {
  bg: string;
  details: {
    price: {
      id: string;
      metadata: {
        iterations: number;
      }
      unit_amount: number;
    };
    product: {
      name: string;
      description: string;
      metadata: {
        flag: string;
        variable: string;
      }
      images: Array<string>;
    };
    plan: {
      id: string;
      unit_amount: number;
      metadata: {
        iterations: number;
      }
    };
  }
  category: string;
  showDescription: boolean;
}

const fetchCheckoutSession = async (price: string, plan: boolean, iterations: number, category: string) => {

  return fetch(api, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "action": "create-checkout",
      "price": price,
      "quantity": 1,
      "plan": plan,
      "iterations": iterations,
      "category": category
    })
  }).then((res) => res.json());
};



function Product(props: IProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlan, setIsLoadingPlan] = useState(false);
  const [price, setPrice] = useState('');
  const [planPrice, setPlanPrice] = useState('');
  const [purchaseLabel, setPurchaseLabel] = useState('Purchase Package');
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (props.details.product) {
      if (props.details.product.metadata.variable) {
        setCount(1);
      } else if (props.details.plan) {
        setCount(props.details.plan.metadata.iterations);
      }
    }
    if (props.details.price) {
      setPrice(
          formatPrice({
            amount: props.details.price.unit_amount,
            currency: 'CAD',
            quantity: 1
          })
      );
    }
    if (props.details.plan) {
      setPlanPrice(
          formatPrice({
            amount: props.details.plan.unit_amount,
            currency: 'CAD',
            quantity: 1
          })
      );
      setPurchaseLabel('One-Time Purchase');
    }
  }, [props.details]);

  const handleClick = async (price: string, plan: boolean, iterations: number, category: string) => {
    plan ? setIsLoadingPlan(true): setIsLoading(true);
    const { sessionId } = await fetchCheckoutSession(price, plan, iterations, category);

    const stripe = await getStripe();
    const { error } = await stripe!.redirectToCheckout({
      sessionId: sessionId,
    });

    console.warn(error.message);
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col rounded-lg shadow-lg">
      <div className={`relative flex-grow bg-${props.bg} rounded-t-lg p-6`}>
        <h3 className="mb-0">{props.details.product.name}</h3>
        {props.showDescription ?
          <p className={`mt-3 mb-0`}>{props.details.product.description}</p>
        : null }
        {props.details.product.metadata.flag === 'value' ? <div className={`absolute top-0 right-0 bg-red-500 text-white text-xs xl:text-sm py-1 xl:py-2 px-4 rounded-bl-lg rounded-tr-lg flex items-center`}><BsStarFill className={`mr-2`}/>Best Value</div> : ''}
      </div>
      <div className="bg-blue-900 rounded-b-lg p-6">
        <div className="flex flex-col">
          {props.details.price ?
            <>
              <div className="flex items-center justify-between">
                <button className="btn flex items-center" role="link" onClick={() => handleClick(props.details.price.id, false, 1, props.category)} disabled={isLoading}>
                  {isLoading || !price
                    ? `Please wait...`
                    : purchaseLabel}
                  <BsBoxArrowRight className={`text-xl ml-2`}/>
                </button>
                <div className={`mb-0 font-bold text-2xl text-white text-right`}>
                  {price}
                </div>
              </div>
            </>
          : null }
          {props.details.plan ?
            <>
              <div className={`${props.details.price ? 'mt-4' : ''} flex flex-wrap items-center justify-between`}>
                <button className="btn bg-green-500 hover:bg-green-600 hover:text-white text-white flex items-center" role="link" onClick={() => handleClick(props.details.plan.id, true, count, props.category)} disabled={isLoadingPlan}>
                  {isLoadingPlan || !planPrice
                      ? `Please wait...`
                      : props.details.price ? `Purchase Payment Plan` : `Purchase Monthly Plan`}
                  <BsBoxArrowRight className={`text-xl ml-2`}/>
                </button>
                <div>
                  <p className="mb-0 font-bold text-2xl text-white text-right">{planPrice} /mo</p>
                  {count ? <div className="self-end md:self-start xl:self-end text-gray-200 text-right">for {count} months</div> : '' }
                </div>
              </div>
            </>
          : null}
          {props.details.product.metadata.variable ?
            <div className={`mt-2 flex text-white items-center`}>
              <div className={`mr-2`}>Duration:</div>
              <button className={`mr-1 bg-white text-black py-1 px-3 font-bold rounded-sm shadow`} onClick={() => setCount(count > 1 ? count - 1 : 1)}>-</button>
              <button className={`bg-white text-black py-1 px-3 font-bold rounded-sm shadow`} onClick={() => setCount(count < 6 ? count + 1 : 6)}>+</button>
            </div>
            : null
          }
          <div>
            <p className="mt-3 mb-0"><span className="text-sm text-gray-200">Tax added at checkout.</span></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
